
export default {
  name: 'HomePage',
  layout: 'landing',
  auth: false,
  head() {
    return {
      title: this.$t('page_title.home'),
    }
  },
}
