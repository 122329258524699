
export default {
  data() {
    return {
      showInfo: false,
      infoWindowContext: {
        position: {
          lat: -33.45694, lng: -70.64827
        }
      },
      center: { lat: -33.45694, lng: -70.64827 },
      branches: [],
      icon: {
        url: '',
        size: {
          width: 20,
          height: 20
        }
      },
      currentPage: 1,
      perPage: 100,
      totalPages: 8,
      currentPageIndex: 1,
      timer: null
    }
  },
  async fetch() {
    await this.fetchBranches();
  },
  methods: {
    async fetchBranches() {
      const { branches }  = await this.$axios.$get(`/api/v1/public/map/branches`);
      this.branches = branches;
    },
    toggleInfoWindow(context) {
      this.infoWindowContext = context;
      this.showInfo = true;
    },
  },
}

